{
  "err_activating_sso_auth": "Error Activating SSO Authentication",
  "err_company_name_length": "Company name must be less than 255 characters.",
  "err_duplicate_value": "That value already exists",
  "err_email_length": "Email must be less than 255 characters.",
  "err_failed_retrieve_initial_state_from_local_storage": "Failed to retrieve initial state from localStorage",
  "err_fetching_filters": "There was a problem fetching these filter options.",
  "err_first_name_contains_url": "First name must not contain URL.",
  "err_first_name_length": "First name must be less than 255 characters.",
  "err_global_modules_invalid_input": "Invalid input.  Make sure you have defined a module name and at least one action is checked.",
  "err_invalid_email": "Please enter a valid email address",
  "err_last_name_contains_url": "Last name must not contain URL.",
  "err_last_name_length": "Last name must be less than 255 characters.",
  "err_no_status_for_status_panel": "Your team has not defined any status options. If you want to start using status, an admin can add status options in Account Settings.",
  "err_password_length_40_chars": "Must be between 8–40 characters.",
  "err_required": "Required",
  "err_unable_to_load_filter_list": "Unable to load filter list",
  "err_unknown_error": "Unknown Error",
  "err_updating_smartsheet_auth": "Error Updating Smartsheet Authentication",
  "err_whoops_something_did_not_work_as_expected": "Whoops! Something didn't work as expected.",
  "lbl_about_fifteen_seconds": "about 15 seconds",
  "lbl_about_thirty_seconds": "about 30 seconds",
  "lbl_accept": "Accept",
  "lbl_account_switcher": "account switcher",
  "lbl_activate": "Activate",
  "lbl_activated_auth_status": "Activated (account created with email only)",
  "lbl_active": "Active",
  "lbl_activity": "Activity",
  "lbl_actual_hours": "Actual Hours",
  "lbl_add": "Add",
  "lbl_add_group_label_filter": "Add {{groupLabel}} Filter",
  "lbl_assign_to_search_user": "Assign to '{{searchInput}}'",
  "lbl_add_lc_members": "Add members",
  "lbl_add_members": "Add Members",
  "lbl_add_person": "Add Person",
  "lbl_add_tasklist": "Add Tasklist",
  "lbl_add_team_member": "Add Team Member",
  "lbl_add_update_people": "Add/Update People",
  "lbl_add_work_item": "Add Work Item",
  "lbl_added": "Added",
  "lbl_all": "All",
  "lbl_all_project_types": "All Project Types",
  "lbl_allocation_heatmap": "Allocation Heatmap",
  "lbl_allocation_percent": "Allocation percent",
  "lbl_amounts": "Amounts",
  "lbl_amounts_and_days": "Amounts & Days",
  "lbl_amounts_and_days_filter_display_name": "Time & Fees: Amounts & Days",
  "lbl_amounts_and_hours": "Amounts & Hours",
  "lbl_apply_changes": "Apply Changes",
  "lbl_approval_access_id": "Approval Access ID",
  "lbl_approval_status": "Approval Status",
  "lbl_approvals": "Approvals",
  "lbl_approvals_card_header_project_phase_client": "Project: Phase - Client",
  "lbl_approve_and_lock": "Approve & Lock",
  "lbl_approved": "Approved",
  "lbl_approved_and_locked": "Approved & Locked",
  "lbl_approved_by": "Approved By",
  "lbl_approver": "Approver",
  "lbl_approver_view": "Approver View",
  "lbl_approvers": "Approvers",
  "lbl_april": "April",
  "lbl_archive": "Archive",
  "lbl_archive_projects": "Archive Projects",
  "lbl_archived": "Archived",
  "lbl_archived_label": "archived-label",
  "lbl_archived_on_date": "Archived on {{archiveDate}}",
  "lbl_archiving": "Archiving",
  "lbl_assign": "Assign",
  "lbl_assign_group_label": "Assign {{groupLabel}}",
  "lbl_assign_team_member": "Assign Team Member",
  "lbl_assign_to": "Assign to",
  "lbl_assignment": "Assignment",
  "lbl_assignment_dates_colon": "Assignment Dates:",
  "lbl_assignment_details": "Assignment Details",
  "lbl_assignment_name": "Assignment name",
  "lbl_assignment_status": "Assignment Status",
  "lbl_audit_log": "Audit Log",
  "lbl_august": "August",
  "lbl_authentication": "Authentication",
  "lbl_author": "Author",
  "lbl_automatic": "automatic",
  "lbl_automatic_capitalize": "Automatic",
  "lbl_availability": "Availability",
  "lbl_availability_sort_options": "Availability Sort Options",
  "lbl_available_hours": "Available Hours",
  "lbl_back": "Back",
  "lbl_back_to_account_settings": "Back to Account Settings",
  "lbl_back_to_list_of_requests": "Back to the list of requests",
  "lbl_based_on": "Based On",
  "lbl_bill_rate": "Bill Rate",
  "lbl_billable": "Billable",
  "lbl_bracket_lc_none": "[none]",
  "lbl_bracket_leave": "[leave]",
  "lbl_bracket_no_approver_assigned": "[No Approver Assigned]",
  "lbl_bracket_non_phase_specific": "[Non-phase specific]",
  "lbl_bracket_none": "[None]",
  "lbl_brief": "Brief",
  "lbl_budget": "Budget",
  "lbl_budget_amounts_filter_display_name": "Budgets: Amounts",
  "lbl_budget_days_filter_display_name": "Budgets: Days",
  "lbl_budget_hours_filter_display_name": "Budgets: Hours",
  "lbl_budget_report": "Budget report",
  "lbl_budgets": "Budgets",
  "lbl_calculating": "calculating",
  "lbl_cancel": "Cancel",
  "lbl_cancelled": "cancelled",
  "lbl_capacity": "Capacity",
  "lbl_category": "Category",
  "lbl_cell_phone": "Cell Phone",
  "lbl_certificate_details": "Certificate Details",
  "lbl_change_to_unmanaged_confirm": "This user will no longer be able to track and approve time & expenses and will no longer be shown in capacity view and reports.",
  "lbl_change_to_managed": "Change to Managed",
  "lbl_change_to_unmanaged": "Change to Unmanaged",
  "lbl_choose_file": "Choose File",
  "lbl_client": "Client",
  "lbl_client_name": "Client Name",
  "lbl_close": "Close",
  "lbl_company_name": "Company Name",
  "lbl_complete": "Complete",
  "lbl_confirm": "Confirm",
  "lbl_confirmed": "Confirmed",
  "lbl_contact_info": "Contact Info",
  "lbl_contact_support": "Contact Support",
  "lbl_contractor": "Contractor",
  "lbl_copy": "Copy",
  "lbl_count_of_total": "{{count}} of {{total}}",
  "lbl_create": "Create",
  "lbl_create_new_request": "* Create a New Request",
  "lbl_current": "Current",
  "lbl_current_work": "Current Work",
  "lbl_custom_date_range": "Custom Date Range",
  "lbl_custom_field": "Custom Field",
  "lbl_date": "Date",
  "lbl_date_string_at_time_string": "{{date}} at {{time}}",
  "lbl_date_string": "Date String",
  "lbl_dates": "Dates",
  "lbl_dates_for_submitted_time": "Dates for submitted time",
  "lbl_day_friday": "Friday",
  "lbl_day_monday": "Monday",
  "lbl_day_saturday": "Saturday",
  "lbl_day_sunday": "Sunday",
  "lbl_day_thursday": "Thursday",
  "lbl_day_tuesday": "Tuesday",
  "lbl_day_wednesday": "Wednesday",
  "lbl_days": "Days",
  "lbl_deactivated": "Deactivated",
  "lbl_december": "December",
  "lbl_delete": "Delete",
  "lbl_delete_adv_report_modal": "Delete report?",
  "lbl_delete_saved_report_modal": "Delete saved report?",
  "lbl_description": "Description",
  "lbl_difference": "Difference",
  "lbl_difference_from_past_scheduled": "Difference From Past Scheduled",
  "lbl_disabled": "disabled",
  "lbl_discipline": "Discipline",
  "lbl_display_colon": "Display:",
  "lbl_done": "Done",
  "lbl_duration": "Duration",
  "lbl_duration_working_days": "Duration (working days)",
  "lbl_edit_profile": "Edit Profile",
  "lbl_edit_project_information": "Edit project information",
  "lbl_edit_projects": "Edit Projects",
  "lbl_email": "Email",
  "lbl_email_must_be_less_than_255_characters.": "Email must be less than 255 characters.",
  "lbl_employee_number": "Employee Number",
  "lbl_enabled": "enabled",
  "lbl_end": "End",
  "lbl_end_date": "End Date",
  "lbl_end_lc_date": "End date",
  "lbl_ends_at": "Ends At",
  "lbl_enter_a_name": "Enter a name",
  "lbl_enter_rejection_reason": "Enter rejection reason",
  "lbl_entry_type": "Entry Type",
  "lbl_everyone": "Everyone",
  "lbl_everything": "Everything",
  "lbl_expense": "Expense",
  "lbl_expenses": "Expenses",
  "lbl_export": "Export",
  "lbl_export_people_list": "Export People List",
  "lbl_export_report_to_csv": "Export This Report to CSV",
  "lbl_export_time_entries_for_quickBooks": "Export Time Entries for QuickBooks",
  "lbl_export_underlying_data_as_csv": "Export Underlying Data as CSV",
  "lbl_failed": "Failed",
  "lbl_february": "February",
  "lbl_fees": "Fees",
  "lbl_filter": "Filter",
  "lbl_filtered": "Filtered",
  "lbl_filters": "Filters",
  "lbl_first_day_of_work": "First Day of Work",
  "lbl_first_group_by": "First group by",
  "lbl_first_name": "First Name",
  "lbl_first_name_lc": "First name",
  "lbl_friday_abbr_uc": "F",
  "lbl_future": "Future",
  "lbl_future_scheduled": "Future Scheduled",
  "lbl_global_modules": "Global Modules",
  "lbl_grid_view": "Grid View",
  "lbl_hours": "Hours",
  "lbl_hours_filter_display_name": "Time & Fees: Hours",
  "lbl_hours_per_day": "Hours per day",
  "lbl_idp_metadata_url": "IdP Metadata URL",
  "lbl_iframe_view_content_require": "Viewing this content requires a Resource Management by Smartsheet license.",
  "lbl_in_the_office": "In the Office",
  "lbl_incurred": "Incurred",
  "lbl_internal": "Internal",
  "lbl_invited": "Invited",
  "lbl_january": "January",
  "lbl_july": "July",
  "lbl_june": "June",
  "lbl_just_now": "Just now",
  "lbl_last_and_next_ninety_days": "Last and Next 90 Days",
  "lbl_last_day_of_work": "Last Day of Work",
  "lbl_last_month": "Last Month",
  "lbl_last_name": "Last Name",
  "lbl_last_name_lc": "Last name",
  "lbl_last_quarter": "Last Quarter",
  "lbl_last_thirty_days": "Last 30 Days",
  "lbl_last_week": "Last Week",
  "lbl_learn_lc_more": "Learn more",
  "lbl_learn_more": "Learn more",
  "lbl_leave": "Leave",
  "lbl_leave_type": "Leave Type",
  "lbl_licensed": "Licensed",
  "lbl_licensed_users": "Licensed Users",
  "lbl_linked_smartsheet_project": "Linked Smartsheet Project",
  "lbl_loading": "Loading",
  "lbl_loading_ellipsis": "Loading...",
  "lbl_loading_project_ellipsis": "Loading project...",
  "lbl_location": "Location",
  "lbl_log_requested": "Log Requested",
  "lbl_make_active": "Make Active",
  "lbl_make_licensed_user": "Make Licensed User",
  "lbl_manage_members": "Manage Members",
  "lbl_managed_resource": "Managed Resources",
  "lbl_manual": "manual",
  "lbl_manual_capitalize": "Manual",
  "lbl_march": "March",
  "lbl_matching_people": "Matching People",
  "lbl_matching_person": "matching person",
  "lbl_matching_placeholders": "Matching Placeholders",
  "lbl_matching_project": "matching project",
  "lbl_matching_projects": "matching projects",
  "lbl_may": "May",
  "lbl_medium": "Medium",
  "lbl_members": "Members",
  "lbl_mobile": "Mobile",
  "lbl_module": "Module:",
  "lbl_module_name": "Module Name",
  "lbl_monday_abbr_uc": "M",
  "lbl_month": "Month",
  "lbl_more_assignments": "more assignments",
  "lbl_must_be_between_8–40_characters.": "Must be between 8–40 characters.",
  "lbl_my_expenses": "My Expenses",
  "lbl_my_projects": "My Projects",
  "lbl_my_time": "My Time",
  "lbl_my_time_and_expenses": "My Time & Expenses",
  "lbl_my_views": "My Views",
  "lbl_new_project": "New Project",
  "lbl_next_ninety_days": "Next 90 Days",
  "lbl_next_sixty_days": "Next 60 Days",
  "lbl_next_thirty_days": "Next 30 Days",
  "lbl_next_week": "Next Week",
  "lbl_no_assignments": "No assignments",
  "lbl_no_description_provided": "No description provided.",
  "lbl_no_imports_found": "No imports found",
  "lbl_non_billable": "Non Billable",
  "lbl_none": "None",
  "lbl_non_matching": "Non-Matching",
  "lbl_not_using_smar": "Not using Smartsheet Authentication",
  "lbl_not_using_sso": "Not using SSO",
  "lbl_not_yet_invited": "Not Yet Invited",
  "lbl_notes": "Notes",
  "lbl_november": "November",
  "lbl_october": "October",
  "lbl_office": "Office",
  "lbl_office_phone": "Office Phone",
  "lbl_office_status": "Office Status",
  "lbl_on_the_road": "On the Road",
  "lbl_or_select_a_specific_phase": "Or select a specific phase",
  "lbl_or_select_phases": "Or select phase(s)",
  "lbl_or_select_specific_phases": "Or select specific phase(s)",
  "lbl_other_projects": "Other Projects",
  "lbl_out_of_office": "Out of Office",
  "lbl_out_sick": "Out Sick",
  "lbl_past": "Past",
  "lbl_pending": "Pending",
  "lbl_pending_approval": "Pending Approval",
  "lbl_people": "People",
  "lbl_people_filters": "People Filters",
  "lbl_people_scheduler": "People Scheduler",
  "lbl_people_tags": "People Tags",
  "lbl_percentage": "Percentage",
  "lbl_persistent_navigation": "Persistent navigation",
  "lbl_person": "Person",
  "lbl_phase": "Phase",
  "lbl_phase_dates": "Phase Dates",
  "lbl_phase_dates_colon": "Phase Dates:",
  "lbl_phase_four": "Phase Four",
  "lbl_phase_lc_name": "Phase name",
  "lbl_phase_name": "Phase Name",
  "lbl_phase_name_long_enough_to_wrap": "Phase Name Long Enough to Wrap",
  "lbl_phase_one": "Phase One",
  "lbl_phase_status": "Phase Status",
  "lbl_phase_three": "Phase Three",
  "lbl_phase_two": "Phase Two",
  "lbl_placeholder": "Placeholder",
  "lbl_placeholders": "Placeholders",
  "lbl_please_sign_in": "Please sign in to continue",
  "lbl_plus_allocation": "+ Allocation",
  "lbl_portfolio_editor": "Portfolio Editor",
  "lbl_portfolio_reporter": "Portfolio Reporter",
  "lbl_portfolio_viewer": "Portfolio Viewer",
  "lbl_post": "Post",
  "lbl_project": "Project",
  "lbl_project_access": "Project Access",
  "lbl_project_code": "Project Code",
  "lbl_project_dates": "Project Dates",
  "lbl_project_dates_colon": "Project Dates:",
  "lbl_project_description": "Project Description",
  "lbl_project_duration": "Project Duration",
  "lbl_project_editor": "Project Editor",
  "lbl_project_filters": "Project Filters",
  "lbl_project_members": "Project Members",
  "lbl_project_name": "Project Name",
  "lbl_project_owner": "Project Owner",
  "lbl_project_owners": "Project Owners",
  "lbl_project_portfolio": "Project Portfolio",
  "lbl_project_properties": "Project Properties",
  "lbl_project_settings": "Project Settings",
  "lbl_project_state": "Project State",
  "lbl_project_tags": "Project Tags",
  "lbl_project_title": "Project Title",
  "lbl_project_type": "Project Type",
  "lbl_projects": "Projects",
  "lbl_purchase_more": "purchase more",
  "lbl_recents": "Recents",
  "lbl_recent_requests": "Recent Requests",
  "lbl_refresh_status": "Refresh status",
  "lbl_refresh_type": "Refresh type",
  "lbl_refreshing_data": "Refreshing data...",
  "lbl_reject": "Reject",
  "lbl_rejected": "Rejected",
  "lbl_remaining": "Remaining",
  "lbl_remove": "Remove",
  "lbl_report_data_refresh_status_error": "Data refresh failed on {{date}} at {{time}}",
  "lbl_report_data_refresh_status_error_please_try_again": "There was an error while creating your advanced report. Please try again. If this problem persists, please contact support.",
  "lbl_report_data_refresh_status_loading": "Updating your advanced report",
  "lbl_report_data_refresh_status_success": "Last refreshed on {{date}} at {{time}}",
  "lbl_report_data_refresh_status_warning_auto": "Changes will be synced with the next data refresh",
  "lbl_report_data_refresh_status_warning_manual": "Refresh advanced report to sync changes",
  "lbl_report_name_colon": "Report Name:",
  "lbl_report_title_by_criteria": "{{report}} by {{criteria}}",
  "lbl_reports": "Reports",
  "lbl_request_an_audit_log": "Request an Audit Log",
  "lbl_request_audit_log": "Request Audit Log",
  "lbl_requester": "Requester",
  "lbl_resend_invitation": "Resend Invitation",
  "lbl_reset": "Reset",
  "lbl_reset_filters": "Reset Filters",
  "lbl_reset_password": "Reset Password",
  "lbl_reset_password_for": "Reset password for",
  "lbl_reset_report": "Reset Report",
  "lbl_resource-request_test_page": "Resource Requests Test Page",
  "lbl_resource_management_by_smartsheet": "Resource Management by Smartsheet",
  "lbl_resourcing_admin": "Resourcing Admin",
  "lbl_retry": "Retry",
  "lbl_role": "Role",
  "lbl_saturday_abbr_uc": "S",
  "lbl_save": "Save",
  "lbl_save_changes": "Save Changes",
  "lbl_save_current_view": "Save Current View",
  "lbl_save_this_report_to_your_personal_page": "Save this report to your personal page",
  "lbl_saving": "Saving",
  "lbl_schedule": "Schedule",
  "lbl_scheduled": "Scheduled",
  "lbl_search": "Search",
  "lbl_search_ellipsis": "Search...",
  "lbl_select_date_range_to_see_entries": "Select a date range to see all entries within that timeframe",
  "lbl_select_people_to_add_to_project": "Select the people who you would like to add to this project.",
  "lbl_select_phases": "Select Phase(s)",
  "lbl_select_project": "Select Project",
  "lbl_select_project_or_phase": "Select Project or Phase",
  "lbl_select_project_owner": "Select Project Owner",
  "lbl_select_team_member": "Select Team Member:",
  "lbl_select_the_entire_project": "Select the entire project",
  "lbl_send": "Send",
  "lbl_send_invitation": "Send Invitation",
  "lbl_september": "September",
  "lbl_set_a_status": "Set a Status",
  "lbl_set_allocation": "Set Allocation:",
  "lbl_set_to_today": "Set to Today",
  "lbl_share": "Share",
  "lbl_shared_views": "Shared Views",
  "lbl_show": "Show",
  "lbl_show_no_phases": "Show: No phases",
  "lbl_sign_out_slo_target_url": "Sign out (SLO) target URL",
  "lbl_signin_sso_target_url": "Sign in (SSO) target URL",
  "lbl_signup_password": "Password (8+ characters)",
  "lbl_skipped": "Skipped",
  "lbl_skipped_colon": "Skipped: {{number}}",
  "lbl_small": "Small",
  "lbl_something_went_wrong_ellipsis": "Something went wrong...",
  "lbl_sort_by": "Sort By",
  "lbl_sso_for_resource_management": "SSO for Resource Management",
  "lbl_start": "Start",
  "lbl_start_date": "Start Date",
  "lbl_start_lc_date": "Start date",
  "lbl_starts_at": "Starts At",
  "lbl_status": "Status",
  "lbl_status_cancelled": "CANCELLED",
  "lbl_status_failed": "FAILED",
  "lbl_status_message": "Status Message",
  "lbl_status_queued": "QUEUED",
  "lbl_status_running": "RUNNING",
  "lbl_status_succeeded": "SUCCEEDED",
  "lbl_submitted_time_for_approval": "Submitted Time for Approval",
  "lbl_sunday_abbr_uc": "S",
  "lbl_switch_to_another_account": "Switch to another account:",
  "lbl_tag": "Tag",
  "lbl_tags": "Tags",
  "lbl_target_utilization": "Target Utilization",
  "lbl_tasks": "Tasks",
  "lbl_team_member": "Team Member",
  "lbl_team_member_type": "Team Member Type",
  "lbl_tentative": "Tentative",
  "lbl_terms": "terms",
  "lbl_the_selected_project_was": "The selected project was ",
  "lbl_then_group_by": "Then group by",
  "lbl_these_results_only_include_projects_that_you_have_permission_to_see": "These results only include projects that you have permission to see.",
  "lbl_this_month": "This Month",
  "lbl_this_quarter": "This Quarter",
  "lbl_this_week": "This Week",
  "lbl_this_year": "This Year",
  "lbl_thursday_abbr_uc": "T",
  "lbl_time": "Time",
  "lbl_time_and_expenses": "Time & Expenses",
  "lbl_time_and_fee_categories": "Time & Fee Categories",
  "lbl_time_and_fees": "Time & Fees",
  "lbl_time_and_fees_report": "Time & Fees report",
  "lbl_time_approval_dashboard": "Time Approval Dashboard",
  "lbl_time_budget_unit_of_measurement": "Time Budget unit of measurement",
  "lbl_time_fees_amounts_and_hours_filter_display_name": "Time & Fees: Amounts & Hours",
  "lbl_time_fees_amounts_display_name": "Time & Fees: Amounts",
  "lbl_time_fees_days_filter_display_name": "Time & Fees: Days",
  "lbl_time_frame": "Time Frame",
  "lbl_time_tracking": "Time Tracking",
  "lbl_today": "Today",
  "lbl_tomorrow": "Tomorrow",
  "lbl_total": "Total",
  "lbl_tuesday_abbr_uc": "T",
  "lbl_type": "Type",
  "lbl_unarchive": "Unarchive",
  "lbl_unarchive_project": "Unarchive Project",
  "lbl_unassigned": "Unassigned",
  "lbl_unconfirmed": "Unconfirmed",
  "lbl_unmanaged": "Unmanaged",
  "lbl_unmanaged_resource": "Unmanaged Resource",
  "lbl_unmanaged_resources": "Unmanaged Resources",
  "lbl_unlock": "Unlock",
  "lbl_upcoming": "Upcoming",
  "lbl_update": "Update",
  "lbl_update_status": "Update Status: ",
  "lbl_updated": "Updated",
  "lbl_updating": "Updating",
  "lbl_utilization": "Utilization",
  "lbl_utilization_target": "Utilization Target",
  "lbl_vacation": "Vacation",
  "lbl_values": "Values",
  "lbl_view": "View",
  "lbl_view_resourcing_schedule": "View Resourcing Schedule",
  "lbl_wednesday_abbr_uc": "W",
  "lbl_week": "Week",
  "lbl_work": "Work",
  "lbl_work_email": "Work Email",
  "lbl_work_item": "Work Item",
  "lbl_work_status": "Work Status",
  "lbl_working_from_home": "Working from Home",
  "lbl_worklist": "Worklist",
  "lbl_your_projects_includes_the_following_properties": "Your Projects: includes the following properties",
  "msg_account_owner_change_attempt": "Change the account owner in account settings before archiving this user.",
  "msg_activated_smartsheet_auth": "Smartsheet Authentication will be activated. This authentication method takes immediate effect and can't be deactivated",
  "msg_add_assignment_to_project": "Add assignment to project",
  "msg_add_notes_placeholder": "Add notes, details, links, etc.",
  "msg_add_phase_to_project": "Add phase to project",
  "msg_add_project_description": "Add Project Description",
  "msg_add_save_current_view": "Add Save Current View",
  "msg_add_team_member_to_project": "Add team member to project",
  "msg_app_project_members_disabled_tooltip": "You own these projects or they're assigned to you.",
  "msg_app_project_members_enabled_tooltip": "You own these projects, are a member, or they’re assigned to you.",
  "msg_audit_log_info": "Important: An audit log gives you an accurate snapshot at the time you request it and may become outdated if there are changes to time or expense entries since the requested log was generated. For the most up-to-date information, request a new log above.",
  "msg_auto_provision_checkbox": "When User Auto Provisioning is active, it will automatically assign licenses and the selected permission level to users. This also allows users to bypass the email invitation process.",
  "msg_bracket_search_to_add_filters": "[Search to add filters]",
  "msg_cannot_archive_account_owner": "You cannot archive the account owner",
  "msg_cert_requirement": "Please use a PEM-encoded filed with CER, CRT, or PEM file extension.",
  "msg_cert_upload_complete": "Certificate upload complete",
  "msg_checkbox_list_aria": "Check uncheck for multiple selections or click a selection button to choose only one",
  "msg_choose_permission_type": "Choose user permission type",
  "msg_choose_property_to_sort_by": "Choose which property you'd like to sort by.",
  "msg_close": "Close",
  "msg_close_and_redirect_to_profile_page": "Close and redirect to profile page",
  "msg_count_matches_found": "{{count}} matches found",
  "msg_copy_url_to_share_report": "Copy the URL below to share this report:",
  "msg_customize_report_prompt": "Customize your report with the options on the left. When you're ready, choose an option below.",
  "msg_delete_adv_report_modal": "This action will stop updating report data but will not delete the underlying report and the source sheet in the destination workspace.",
  "msg_delete_assignment": "Delete Assignment",
  "msg_delete_saved_report": "Delete Saved Report",
  "msg_delete_saved_report_modal": "This action cannot be undone but you can create a new report with the same options.",
  "msg_delete_view": "Delete View",
  "msg_delete_work_item_warning": "Are you sure you want to permantently delete this work item? All associated tasks and assignments will be removed. This can not be undone.",
  "msg_edit_allocation": "Edit allocation",
  "msg_edit_end_date": "Edit end date",
  "msg_edit_start_date": "Edit start date",
  "msg_edit_status": "Edit Status",
  "msg_edit_team_member": "Edit team member",
  "msg_edit_work_item": "Edit work item",
  "msg_enable_bulk_editing_requirements": "To enable bulk editing, please ensure the Project Owner filter is set to yourself and the Project State filter is set to Active.",
  "msg_enable_module_for_existing_organizations": "Enable module for existing organizations",
  "msg_enable_module_for_new_organizations": "Enable module for new organizations",
  "msg_filter_is_locked_to_the_projects_you_have_permission_to_report_on": "This filter is locked to the projects you have permission to report on.",
  "msg_filter_is_locked_to_the_projects_you_own_or_are_assigned_to": "This filter is locked to the projects you own or are assigned to.",
  "msg_grid_view_aria": "Grid View: Activate to choose grid view.",
  "msg_hold_on_loading_ellipsis": "Hold on we are loading...",
  "msg_infinite_loader_aria_label": "Check uncheck for multiple selections or click a selection button to choose only one",
  "msg_invalid_cert": "Sorry, the certificate you selected is not a valid file type.",
  "msg_list_view_and_project_field_selector": "List View and Project Field Selector: Activate to choose list view and select which project fields are made available.",
  "msg_loading_approval_status": "Loading approval status...",
  "msg_loading_page": "Loading page",
  "msg_loading_project_timeline_ellipsis": "Loading project timeline...",
  "msg_loading_project_users_ellipsis": "Loading project users...",
  "msg_make_bulk_project_archive_warning": "Archiving these projects will end ongoing and remove future assignments. This cannot be undone.",
  "msg_make_bulk_project_edit_confirmation": "Are you sure you want to make this change?",
  "msg_make_bulk_project_edit_warning": "This may take several minutes and cannot be undone.",
  "msg_manage_project_membership_and_project_access_levels_based_on_their_account_permissions": "Manage project membership and project access levels. Access levels are exclusive to this project. Some people can edit without membership based on their <1>account permissions</1>.",
  "msg_need_a_minute_ellipsis": "Whoops! We need a minute...",
  "msg_new_work_item_title": "New work item title",
  "msg_no_assignments": "No assignments",
  "msg_no_matches_found": "No matches found",
  "msg_no_people_assigned_to_project": "There are no people assigned to this project.",
  "msg_no_phases_to_display": "No phases to display",
  "msg_no_projects_match_your_criteria": "No projects match your criteria.",
  "msg_no_propertyies_to_display": "No properties to display",
  "msg_no_recent_requests": "No recent requests",
  "msg_no_results_to_display": "No results to display",
  "msg_no_work_for_this_project": "There is no current work for this project.",
  "msg_not_available": "not available",
  "msg_one_match_found": "1 match found",
  "msg_owners_of_projects_permission_to_report_on": "The owners of projects that you have permission to report on.",
  "msg_people_field_selector": "People Field Selector: Activate to select which data fields are made available.",
  "msg_people_settings_info": "Licensed Users can track time and tasks on scheduled assignments and can be optionally invited to login. Managed Resources can be scheduled on projects, but cannot track time and do not have login access.",
  "msg_people_list_licensed_tooltip": "Based on permissions, licensed users can track and approve time, plan projects, manage staffing, edit user profiles, and generate reports.",
  "msg_people_list_managed_tooltip": "Upgrade Managed Resources for time tracking capabilities.",
  "msg_people_list_unmanaged_tooltip": "Upgrade Unmanaged Resources to include them in capacity view and reports.",
  "msg_people_list_archived_tooltip": "Assignments for the archived users remain on the project page for reassignment to another person or placeholder.",
  "msg_phase_picker_copy": "Copy to Project or Phase",
  "msg_print_brief_page": "Print Brief Page",
  "msg_profile_banner": "Profile banner",
  "msg_profile_information": "Profile Information",
  "msg_profile_navigation": "Profile Navigation",
  "msg_profile_navigation_menu": "Profile Navigation Menu",
  "msg_project_members_tooltip": "You own these projects, are a member, or they're assigned to you.",
  "msg_project_navigation": "Project navigation",
  "msg_project_members_description": "Project membership is unique to this project. Some users are able to access this project without being a member based on their <0>account permissions</0>.",
  "msg_reassign_search_placeholder": "Search name, discipline, etc.",
  "msg_refine_search_to_see_more_users": "Refine search to see more matching users",
  "msg_rejection_reason_required": "A reason is required for rejection.",
  "msg_remove_archived_project_state_to_enable_editing": "Remove 'Archived' from the Project State filter to enable editing.",
  "msg_remove_assignment_confirmation": "Are you sure you want to remove this assignment?",
  "msg_report_data_refresh_err_detail_cell_limit_exceeded": "We weren't able to refresh your report data because it contains 3,000 cells more than the limit. Reduce the size of your report by adjusting the options in the left panel. <1>Learn more.</1>",
  "msg_report_data_refresh_err_detail_generic": "There was an error while refreshing your advanced report data. Please create a new advanced report.",
  "msg_report_data_refresh_err_detail_row_limit_exceeded": "We weren't able to refresh your report data because it contains 300 rows more than the limit. Reduce the size of your report by adjusting the options in the left panel. <1>Learn more.</1>",
  "msg_report_data_refresh_err_detail_sheet_access": "There was an error while refreshing your advanced report data. Please update sheet permissions or create a new advanced report.",
  "msg_report_is_too_big_to_load": "Sorry, that report is too big to load",
  "msg_save_report_analytics_page": "Save this report to the analytics page",
  "msg_search_contacts": "Search contacts",
  "msg_settings_people_manage_info": "Manage the <1>login access</1> and <3>permissions</3> for people in your account.<br /><5>Contact us here</5> to make changes to your subscription.",
  "msg_share_report": "Copy the URL via copy button below to share this report:",
  "msg_sheet_collaborators": "Sheet collaborators on linked projects aren't shown here. To update sheet sharing or add sheet collaborators, please manage them in Smartsheet. <0>Learn more</0>",
  "msg_signup_loading_transition": "We are setting up your free trial account.",
  "msg_sort_by_last_name": "Sort by: last name",
  "msg_specify_budget_in_unit": "Specify this budget in {{curUnit}}",
  "msg_sso_configuration_info": "The recommended Automatic Configuration simplifies SSO configuration, and will support multiple certificates by default. Manual configuration only supports one certificate at a time.",
  "msg_sso_enabled": "SSO is currently enabled for your account",
  "msg_sso_info": "Using Single Sign-On (SSO) for password management and user provisioning allows greater company control of access to Resource Management, ensuring the right people are securely logging into the Service.",
  "msg_sso_login_failure": "Sorry, we could not sign you into Resource Management by Smartsheet.",
  "msg_sso_not_configured": "SSO has not been configured for your account",
  "msg_sso_unconfigurable": "SSO can't be configured directly in Resource Management because your organization is currently using Smartsheet Authentication. SSO settings can be managed in Smartsheet Admin Center",
  "msg_terms": "Terms",
  "msg_thanks": "Thanks!",
  "msg_there_are_no_approvals_matching_your_current_filters": "There are no approvals matching your current filters.",
  "msg_to_inactive_project": "to inactive project",
  "msg_transfer_project_ownership_to_remove_user": "{{member.name}} is the Project Owner and can’t be removed. Transfer ownership in the Project Settings tab.",
  "msg_type_and_press_enter_to_add_task": "Type and press enter to add task",
  "msg_cant_edit_remove_unmanaged_resource": "{{member.name}} is an Unmanaged Resource. Upgrade them in Account Settings to edit or remove them from this project.",
  "msg_cant_add_unmanaged_resource": "{{member.name}} is an Unmanaged Resource. Upgrade them in Account Settings to add them to this project.",
  "msg_upload_failed": "Upload Failed",
  "msg_upload_succeeded": "Upload Succeeded",
  "msg_user_default_login_tooltip": "This person has not yet signed in using SSO. To switch, ask them to sign out and sign back in via SSO.",
  "msg_user_smar_login_tooltip": "This person has not yet signed in using Smartsheet Authentication. To switch, ask them to sign out and sign back in via Smartsheet Authentication.",
  "msg_wait_while_load_assignments": "Please wait while we load the assignments",
  "msg_welcome_to_resource_management_by_smartsheet": "Welcome to Resource Management by Smartsheet",
  "msg_work_item_description_placeholder": "Describe the work, assignment, etc.",
  "msg_you_dont_own_these_projects_and_theyre_not_assigned_to_you": "You don't own these projects and they're not assigned to you.",
  "msg_saved_form_already_exists": "already exists. Would you like to replace it?",
  "msg_you_do_not_have_admin_access": "Make sure you have admin permission on the workspace you're looking for. You can request access or create a new destination workspace in <0>Smartsheet</0>.",
  "msg_no_matching_results": "No matching results",
  "msg_report_filter_list": "You are on the Report Filter List. Choose what data you'd like included in your report.",
  "msg_report_filter_show_group": "You are on the Report Filter Show Group. Choose what options in each category that you&quot;d like to show, by choosing all or none, marking/unmarking the checkboxes for multiple choice or activating an a joining button to choose only one.",
  "lbl_getting_information": "Getting Information",
  "lbl_actions": "Actions",
  "lbl_delete_phase": "Delete Phase",
  "lbl_chars_left": "left",
  "lbl_set_to": "Set to",
  "msg_no_ppl_match": "No people match your criteria",
  "lbl_user_info_summary": "User Info Summary",
  "lbl_saved_reports": "Saved Reports",
  "lbl_no_active_assignments": "No active assignments.",
  "msg_entries_not_shown": "Entries for unowned projects are not shown",
  "msg_provide_note": "Provide a note about this approval. This is required for rejection",
  "msg_taking_longer_than_expected": "This is taking longer than expected. Just a moment",
  "msg_want_to_make_adjustments": "Want to make adjustments or speed things up? You can continue to use the filters to refine or scope down the report.",
  "msg_calculating_time_entries": "Calculating ~{{timeEntriesCount}} time entries.",
  "lbl_just_a_few_seconds": "just a few seconds",
  "lbl_less_than_a_min": "less than a minute",
  "lbl_about_a_min": "about a minute",
  "msg_add_users_intro": "Use this page to add new people or update existing people in your account.",
  "msg_add_projects_intro": "Use this page to add new projects or update existing projects in your account.",
  "msg_more_details_can_be_found": "More details can be found in our <0>help topic</0>. Questions? <1>Contact</1> our support team.",
  "lbl_step_1_prepare_your_data": "1. Prepare your data",
  "lbl_step_1_content_users": "Note: Don't edit the User ID column - this is used for matching updates to existing people.",
  "lbl_step_1_content_projects": "Note: Don't edit the Project ID column - this is used for matching updates to existing projects.",
  "lbl_step_1_contents_create_users": "Use this template to add new people to your account.",
  "lbl_step_1_contents_create_projects": "Use this template to add new projects to your account.",
  "lbl_step_1_contents_edit_users": "Export a list of all the people in your organization to update their attributes.",
  "lbl_step_1_contents_edit_projects": "Export a list of all the projects in your organization to update their attributes.",
  "lbl_people_lc": "people",
  "lbl_projects_lc": "projects",
  "lbl_add_update_projects": "Add/Update Projects",
  "lbl_download_template": "Download Template",
  "lbl_update_existing": "Update existing",
  "lbl_export_project_list": "Export Project List",
  "lbl_step_2_upload_data_users": "2. Upload your updated people list",
  "lbl_step_2_upload_data_projects": "2. Upload your updated project list",
  "msg_select_csv_file": "Select and upload your updated CSV file in order to add or update {{importType}}. An email confirmation will be provided when complete.",
  "lbl_send_invitation_new_licensed_users": "Send an invitation to new Licensed Users.",
  "lbl_upload": "Upload",
  "lbl_processing": "Processing...",
  "lbl_add_new": "Add new {{importType}}",
  "msg_import_in_progress": "Import in progress",
  "msg_errors_during_import": "Errors found during import",
  "lbl_import_complete": "Import complete",
  "lbl_added_colon": "Added: {{number}}",
  "lbl_updated_colon": "Updated: {{number}}",
  "lbl_failed_colon": "Failed: {{number}}",
  "lbl_download_error_report_csv": "Download Error Report CSV",
  "msg_working_on_import_background": "We're working on this import in the background. You will receive an email when the import has completed. A link to this page can be found at the bottom of the <0>{{pageTitle}}</0> page.",
  "msg_rows_with_errors": "Rows with errors were not added or updated. You can download a CSV report with these errors to correct and try again. Additional errors may exist, please verify all data before re-uploading. <0>Learn more</0> about importing and updating {{importType}}.",
  "msg_completed_processing": "We've completed processing the {{importType}} you recently added and updated into your organization.",
  "lbl_imports_last_30_days": "Imports from the last 30 days",
  "lbl_characters": "characters ",
  "lbl_character": "character ",
  "lbl_remaining_lc": "remaining",
  "msg_no_items_match_criteria": "No items match your criteria",
  "lbl_future_scheduled_lc": "future scheduled",
  "lbl_loading_details": "Loading Details",
  "lbl_try_again": "Try again",
  "err_must_not_be_blank": "Must not be blank",
  "err_cannot_be_more_than_255_char": "Cannot be more than 255 characters",
  "lbl_view_name": "View Name",
  "lbl_project_timelines": "Project Timelines",
  "msg_shorten_time_frame": "Please shorten the time frame or filter to fewer people or projects, and then try reloading the report. Or, request an email with a link to a CSV of this report.",
  "msg_underlying_data_requested": "Underlying Data requested. We will email you once it is ready for download. This may take a while.",
  "msg_no_report_values": "No values for that report.",
  "msg_report_only_includes_data": "This report only includes data that you have permission to see.",
  "msg_we_have_logged_error": "We've logged the error and will look into it. In the meantime, try starting again fresh:",
  "msg_budgets_report_includes_all": "This report includes all incurred, future scheduled, and budget data for the entire duration of projects that have dates overlapping",
  "lbl_run_report": "Run Report",
  "lbl_request_underlying_data": "Request Underlying Data",
  "lbl_selected": "Selected",
  "lbl_selected_lc": "selected",
  "lbl_project_lc": "project",
  "msg_change_client_name": "This will change the client for the",
  "msg_change_project_type": "This will change the project type for the",
  "msg_change_project_owner": "This will change the project owner for the",
  "msg_change_tags": "This will change the tag(s) for the",
  "msg_change_custom_fields": "This will change the custom fields for the",
  "msg_this_will_archive_the": "This will archive the",
  "msg_this_will_add_selected_tags": "This will add the selected tag(s)",
  "msg_this_will_remove_selected_tags": "This will remove the selected tag(s)",
  "msg_updating_client": "Updating client for",
  "msg_updating_project_owner": "Updating Project Owner for",
  "msg_describe_work_item_placeholder": "Describe the work, outcome or deliverable",
  "msg_show_hide_phase_items": "Show/hide Phase {{phaseName}} work items",
  "msg_edit_phase": "Edit Phase {{phaseName}}",
  "msg_show_hide_project_items": "Show/hide Project {{projectName}} work items",
  "msg_edit_project": "Edit Project {{projectName}}",
  "lbl_hours_lc": "hours",
  "lbl_hours_per_day_abbr": "h/d",
  "lbl_new": "New",
  "lbl_add_team_member_to": "Add Team Member to {{parentLabel}}",
  "lbl_add_work_item_to": "Add Work Item to {{parentLabel}}",
  "lbl_available_hours_lc": "available hours",
  "lbl_remaining_or_budget_forecast": "remaining or {{budgetForecastPercent}}",
  "lbl_forecasted_over_or_under_budget": "forecasted {{overUnder}} budget or {{budgetForecastPercent}}",
  "lbl_under_lc": "under",
  "lbl_over_lc": "over",
  "lbl_expense_budget_lc": "expense budget",
  "lbl_project_budget_lc": "project budget",
  "lbl_expensed_lc": "expensed",
  "lbl_incurred_lc": "incurred",
  "lbl_add_description": "Add description",
  "lbl_automatic_configuration": "Automatic Configuration",
  "lbl_recommended_paren": "(Recommended)",
  "lbl_manual_configuration": "Manual Configuration",
  "lbl_manage_sheet_sharing": "Manage sheet sharing",
  "lbl_cert_must_be_PEM": "Certificate must be PEM encoded (i.e .pem, .cert, .crt, .cer files)",
  "msg_auto_provision_auth_users": "Auto-provision authenticated users not in account (created with email only)",
  "lbl_selected_phases": "Use selected phases",
  "lbl_no_display_props": "No properties to display",
  "msg_approval_audit_error": "Sorry, something unexpected happened. If this continues to be a problem, please contact support with details at ",
  "msg_download_error": "An error occurred while trying to download file: ",
  "lbl_audit_log_req": "Audit Log Requested",
  "msg_audit_log_req": "A download link will appear in the recent requests table below. This could take a few minutes.",
  "lbl_audit_log_expense": "Expense Audit Logs",
  "lbl_download": "Download",
  "lbl_proj_singular": "project was",
  "lbl_proj_plural": "projects were",
  "lbl_zero_proj": "0 projects",
  "lbl_success_lc": "success",
  "lbl_important": "<1>Important:</1>",
  "lbl_edit_properties": "Edit Properties",
  "lbl_error": "Error",
  "lbl_change": "Change",
  "lbl_select_new": "Select new",
  "lbl_approver_project": "Approver: ",
  "lbl_successfully": " successfully.",
  "msg_update": "Update Complete",
  "lbl_of": "of",
  "msg_update_failed": "Updates Failed.",
  "msg_update_lc": "updated successfully.",
  "lbl_edit_desc": "Edit description",
  "lbl_no_select": "nothing selected",
  "lbl_current_filter_select": " : Currently set to ",
  "msg_getting_ready": "Getting ready...",
  "msg_retry_update": "Click retry to attempt to fix failed updates.",
  "msg_error_support": "If you continue to see this error, please contact support. ",
  "lbl_curr_dropdown_val": "Current dropdown value is ",
  "msg_cancel_view": "Cancel and return to view list",
  "msg_updating_tags": "Updating tags for",
  "msg_updatings_proj_type": "Updating project type for",
  "msg_start_your_trial_for_free": "Start your {{trialDuration}}-day trial for free",
  "msg_create_free_account": "Create My Free Account",
  "msg_I_agree_to_terms_and_conditions": "I agree to the <0>Terms</0> and acknowledge that Resource Management by Smartsheet will process my personal data in compliance with the Privacy Notice <1>Privacy Policy</1>",
  "msg_something_went_wrong_signup": "Oops! Something went wrong with your signup. Please contact us through our <0>Support page</0>",
  "msg_edit_phase_settings": "Edit Phase Settings",
  "msg_edit_project_settings": "Edit Project Settings",
  "msg_shift_phase_dates": "Shift Phase Dates",
  "msg_shift_project_dates": "Shift Project Dates",
  "lbl_utilization_lc": "utilization",
  "lbl_target_utilization_lc": "target utilization",
  "msg_organization_using_smar_auth_modifification_info": "Your organization is currently using Smartsheet Authentication to log into Resource Management. To modify this setting, please <1>contact support</1>",
  "lbl_user_auto_provisioning": "User Auto Provisioning",
  "lbl_edit": "Edit",
  "lbl_permission_level": "Permission Level",
  "msg_activate_smar_auth_info": "Activate to allow users to login with their Smartsheet account. <1>Learn more</1>.",
  "lbl_activate_smar_auth": "Activate Smartsheet Authentication",
  "msg_please_link_rm_account_with_smar": "To allow Smartsheet Authentication, <1>please link your Resource Management account with your Smartsheet account.</1>",
  "lbl_setup_sso": "Setup SSO",
  "lbl_smartsheet_authentication": "Smartsheet Authentication",
  "lbl_edit_sso": "Edit SSO Settings",
  "msg_disabled_filter_info": "This option is not available for advanced reports. Create a <0>new report</0> to access this option.",
  "msg_people_in_org_have_logged_in_using_sso": "People in your organization have logged in using SSO already. Incorrectly editing your SSO settings could result in preventing users to log in. To update your SSO settings safely please <1>contact us</1> for support.",
  "err_issue_enabling_sso": "There was an issue enabling SSO Authentication: {{error}}. If this issue persists, please contact support.",
  "err_activating_smar_auth": "Error Activating Smartsheet Authentication",
  "err_issue_updating_smar_auth": "There was an issue updating Smartsheet Authentication: {{error}}. If this issue persists, please contact support.",
  "err_issue_enabling_smar_auth": "There was an issue enabling Smartsheet Authentication: {{error}}. If this issue persists, please contact support.",
  "msg_report_unmanaged_resources_admin": "To include Unmanaged Resources in this report, upgrade them in <0>Account Settings</0>.",
  "msg_report_unmanaged_resources": "To include Unmanaged Resources in this report, ask your admin to upgrade them in Account Settings.",
  "lbl_adjust_refresh_settings": "Adjust data refresh settings",
  "lbl_view_advanced_report": "View advanced report",
  "lbl_save_and_refresh_data": "Save changes and refresh data",
  "lbl_refresh_data": "Refresh data",
  "lbl_delete_report": "Delete report",
  "lbl_toggle_reports_control_menu": "Toggle reports control menu",
  "lbl_advanced_report": "Advanced report",
  "lbl_create_new_advanced_report": "Create new advanced report",
  "msg_open_the_advanced_report_in_smartsheet": "Open the advanced report in Smartsheet",
  "lbl_advanced_report_edit_modal_title": "Data refresh settings",
  "lbl_advanced_report_modal_title": "Create advanced report",
  "msg_advanced_report_modal_description": "Send your data to a source sheet and Smartsheet Report. You can update the report and adjust the settings from the Analytics page.",
  "lbl_advanced_report_modal_name_field": "Name",
  "lbl_advanced_report_modal_destination_field": "Destination",
  "lbl_advanced_report_modal_data_refresh_field": "Data refresh setting",
  "lbl_advanced_report_modal_data_refresh_auto_name": "Automatic",
  "lbl_advanced_report_modal_data_refresh_auto_description": "Advanced report data will refresh every 24 hours",
  "lbl_advanced_report_modal_data_refresh_manual_name": "Manual",
  "lbl_advanced_report_modal_data_refresh_manual_description": "Data must be manually refreshed from the Analytics page",
  "lbl_advanced_report_modal_close_button": "Close",
  "lbl_advanced_report_modal_create_button": "Create",
  "msg_advanced_report_modal_need_lws": "Ask your resourcing admin to activate Smartsheet authentication to use this feature.",
  "lbl_destination_dropdown_select_workspace": "Select a workspace",
  "lbl_destination_dropdown_search_for_workspace": "Search for a workspace",
  "msg_advanced_report_ready_title": "Your advanced report is ready!",
  "msg_advanced_report_ready": "You can always access and view the status of your advanced report from the Analytics page",
  "lbl_advanced_report_view_btn": "View advanced report",
  "msg_advanced_report_creating_title": "Creating your advanced report",
  "msg_advanced_report_creating": "This may take a few minutes. You can preview the report with sample data now and your actual data will replace it once it's ready.",
  "lbl_advanced_report_preview_btn": "Preview Report...",
  "msg_advanced_report_creation_footer": "You're safe to close. You can return later to find this in your saved reports or selected workspace.",
  "lbl_advanced_report_creation_footer_link": "Explore tips on advanced reports.",
  "lbl_create_advanced_report": "Create advanced report",
  "lbl_edit_settings": "Edit report settings",
  "err_create_report_failed_generic": "There was an error creating your report. Please try again.",
  "err_edit_report_failed_generic": "There was an error editing your report. Please try again.",
  "err_create_report_failed_invalid_name": "Report name must not contain any special characters or emojis",
  "msg_session_expired_title": "Your session has expired",
  "msg_session_expired_description": "Your session in this window is no longer valid. Click the button below to refresh the page. You may need to log in again.",
  "lbl_refresh_page": "Refresh page",
  "msg_refresh_advanced_report": "Refresh data from the menu to apply your filters to the advanced report. Your saved RM report will be updated as well.",
  "msg_advanced_report_too_many_cells": "This report failed to refresh because it has 500,000 or more cells. Please adjust the settings in the left panel to reduce number of cells. <0>Learn more</0>",
  "msg_advanced_report_too_many_columns": "This report failed to refresh because it has 400 or more columns. Please adjust the settings in the left panel to reduce number of columns. <0>Learn more</0>",
  "msg_advanced_report_too_many_rows": "This report failed to refresh because it has 20,000 or more rows. Please adjust the settings in the left panel to reduce number of rows. <0>Learn more</0>",
  "msg_advanced_report_auto_refresh_disabled": "If this report's refresh setting is set to automatic, it will be switched to manual to prevent further failed refreshes."
}
